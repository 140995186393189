import React from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import FooterForm from "../../components/common/FooterForm";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import CenterContentHome from "../../components/common/CenterContentHeader";
import { RequestForm } from "../../components/form";
import {GoToPopup} from "../homepage"

const topImage = require("../../assets/images/10-pages/usp_1/no-code_header.png");

const section_one = require("../../assets/images/10-pages/usp_1/no-code_sec_1.png");
const section_two = require("../../assets/images/10-pages/usp_1/no-code_sec_2.png");
const section_three = require("../../assets/images/10-pages/usp_1/no-code_sec_3.png");
const section_four = require("../../assets/images/10-pages/usp_1/no-code_sec_4.png");
const section_five = require("../../assets/images/10-pages/usp_1/no-code_sec_5.png");

const section_3_mob = require("../../assets/images/10-pages/usp_1/no_code_sec_3_mob.png");

const featureContent = [
  {
    header: "No-code chatbot canvas",
    content:
      "Build awesome dialogs and deliver engaging end-user experiences using workativ assistant’s simple and easy to use no-code chatbot builder canvas. Whether you want the chatbot to execute tasks, provide menu options to end users, validate user details, or simply do small talk - we got your covered.",
    link: null,
    image: section_one,
    alt: "no code chatbot platform"
  },
  {
    header: "Personalize user experience",
    content:
      "Workativ help you customize chatbot experience for end-users easily such as chatbot onboarding and offboarding, session inactivity, collecting feedback, small talk etc. Use adaptive cards to format data in card view with images, links and buttons for better user experience.",
    link: null,
    image: section_two,
    alt: "customize chatbot    "
  },
  {
    header: "Live A/B test your chatbot",
    content:
      "Chatbot testing is critical part of building great user experience. Testing chatbot using our no-code chatbot platform helps you debug and optimize dialogs, add/edit intents, user utterance, review context on the go.",
    content1:
      "Also use chatbot analytics to track how employees use chatbots, drill down on most helpful conversations, peak durations, average time to resolution, most frequently used channels and much more.",
    link: null,
    image: section_three,
    imagemob: section_3_mob,
    alt: "chatbot a/b test - workativ assistant"
  },
  {
    header: "Add pre-built workflow automations ",
    content:
      "Integrate chatbot with various apps in a click and add 400+ pre-built workflows to chatbot using our no-code workflow automation builder in workativ assistant at no additional cost. Just download, connect and go live in minutes.",
    link: null,
    image: section_four,
    alt: "pre-built automations for chatbot"
  },
  {
    header: "Deploy chatbot easily on chat apps",
    content:
      "Turn your favorite collaboration app into modern employee self-service by deploying the chatbot on your MS Teams or Slack in few clicks without any coding. Customize the end-user experience by applying your company branding, logo etc.",
    link: null,
    image: section_five,
    alt: "deploy chabot on chat apps with workativ"
  }
];

const SlackAndTeams = () => (
  <div className="chat_channels_trial">
    <div className="chat_channels_trial_btn">
      <img loading = "lazy"
        src={require("../../assets/images/trial_landing/slack.png")}
        alt="slack"
      />
      Slack
    </div>
    <div className="chat_channels_trial_btn">
      <img loading = "lazy"
        src={require("../../assets/images/trial_landing/teams.png")}
        alt="teams"
      />
      Teams
    </div>
  </div>
);

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Free No-code Chatbot | Workativ Assistant Chatbot"
        description="Use no-code chatbot platform to build an intelligent chatbot in less than 60 mins and deploy on Slack or Microsoft Teams. Transform your workplace support."
        keywords={[
          "Workativ  trial",
          "workativ free trial",
          "helpdesk chatbot free trial",
          "automation"
        ]}
        ogImage={topImage}
        ogTitle="Free No-code Chatbot | Workativ Assistant Chatbot"
        ogDescription="Use no-code chatbot platform to build an intelligent chatbot in less than 60 mins and deploy on Slack or Microsoft Teams. Transform your workplace support."
      />
      <Container>
        <Layout backgroundColor={"bg_usp_page_one"} logoFor="ASSISTANT">
          <RegisterSection
            rightImage={topImage}
            backgroundColor={"bg_usp_page_one"}
            altImage={"no code chatbot"}
          >
            <RegisterSection.FormWithContent>
              <RegisterSection.MainHeader>
                Lead the no-code revolution - simple yet powerful
              </RegisterSection.MainHeader>
              <RegisterSection.SubHeader>
                Workativ’s easy-to-use no-code chatbot platform makes it easy to
                build AI-powered chatbots with workflow automation in minutes.
              </RegisterSection.SubHeader>
            </RegisterSection.FormWithContent>
          </RegisterSection>{" "}
          <CenterContentHome>
            <CenterContentHome.Header>
              What makes our no-code platform different
            </CenterContentHome.Header>
          </CenterContentHome>
          {featureContent.map((feature, index) => {
            if (index % 2 !== 0) {
              return (
                <RightImageWithContentFeature
                  ImageMob
                  rightImage={feature.image}
                  rightImageMobile={feature.imagemob}
                  image={feature.image}
                  altImage={feature.alt}
                >
                  <RightImageWithContentFeature.Header>
                    <h3>{feature.header}</h3>
                  </RightImageWithContentFeature.Header>
                  <RightImageWithContentFeature.SubHeader>
                    <p>{feature.content}</p>
                    {feature.content1 && (
                      <p
                        className="second_p"
                        dangerouslySetInnerHTML={{ __html: feature.content1 }}
                      />
                    )}
                  </RightImageWithContentFeature.SubHeader>
                  <RightImageWithContentFeature.Link>
                    {feature.link && (
                      <div className="card_link_landing">
                        <a href={feature.url} className="url_manipulation">
                          {feature.link}
                          <span className="arrow_svg_link">
                            <ArrowRightICon />
                          </span>
                        </a>
                      </div>
                    )}
                    {feature.toDisplaySlackIcons != undefined && (
                      <SlackAndTeams />
                    )}
                  </RightImageWithContentFeature.Link>
                </RightImageWithContentFeature>
              );
            }
            return (
              <LeftImageWithContent
                ImageMob
                rightImage={feature.image}
                rightImageMobile={feature.imagemob}
                image={feature.image}
                altImage={feature.alt}
              >
                <LeftImageWithContent.MainHeader>
                  {feature.header}
                </LeftImageWithContent.MainHeader>
                <LeftImageWithContent.SubHeader>
                  <p>{feature.content}</p>
                  {feature.content1 && (
                    <p
                      className="second_p"
                      dangerouslySetInnerHTML={{ __html: feature.content1 }}
                    />
                  )}
                </LeftImageWithContent.SubHeader>
                <LeftImageWithContent.Link>
                  <div className="card_link_landing">
                    {feature.link && (
                      <a href={feature.link} className="url_manipulation">
                        Learn more{" "}
                        <span className="arrow_svg_link">
                          <ArrowRightICon />
                        </span>
                      </a>
                    )}
                    {feature.toDisplaySlackIcons != undefined && (
                      <SlackAndTeams />
                    )}
                  </div>
                </LeftImageWithContent.Link>
              </LeftImageWithContent>
            );
          })}
          <GoToPopup />
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
